<template>
  <v-dialog
      :value="show"
      width="50%"
      persistent
  >
    <v-card>
      <v-card-title>
        Loading...
      </v-card-title>
      <v-card-text>
        <v-progress-linear
          :value="progress"
          :height="25"
        >
        {{ progress }}%
       </v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'LinearProgressNum',
  props: ['show', 'progress'],
  computed: {
    computedShow: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
  },
};
</script>

<style scoped>
</style>
